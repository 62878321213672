;(function () {

  'use strict';

  /******************************************
   * Type Tester
   *
   ******************************************/

  // Find all individual type-testers
  const tt = document.querySelectorAll('[data-type-tester]:not([data-type-tester="in-group"])');

  // Find all type-tester groups
  const ttg = document.querySelectorAll('[data-type-tester-group]');

  const initTypeTester = function(tester) {
    let renderedFontSize = parseInt(window.getComputedStyle(tester.querySelector('[data-type-tester-content]'), null).getPropertyValue('font-size'), 10);
    tester.querySelector('[data-type-tester-control-size]').value = renderedFontSize;
    if (tester.querySelector('[data-type-tester-font-size]')) {
      tester.querySelector('[data-type-tester-font-size]').innerText = `${renderedFontSize}px`;
    }
  };

  const changeFont = function(tester, font) {
    tester.querySelector('[data-type-tester-content]').dataset.typeTesterContent = font;
    let specimenContent = tester.querySelectorAll('[data-type-tester-specimen-content] [data-typeface]');
    Array.prototype.forEach.call(specimenContent, function(item) {
      item.dataset.typeface = font;
    });
  };

  const changeSize = function(tester, size) {
    let newSize = `${size}px`;
    tester.querySelector('[data-type-tester-content]').style.fontSize = newSize;
    if (tester.querySelector('[data-type-tester-font-size]')) {
      tester.querySelector('[data-type-tester-font-size]').innerText = newSize;
    }
  };

  const changeTracking = function(tester, tracking) {
    tester.querySelector('[data-type-tester-content]').style.letterSpacing = `${tracking}em`;
  };

  const changeLeading = function(tester, leading) {
    tester.querySelector('[data-type-tester-content]').style.lineHeight = `${leading}`;
  };

  const initTypeTesterListeners = function (tester) {
    tester.addEventListener('input', function (ev) {
      if (ev.target.matches('[data-type-tester-control-font]')) {
        changeFont(tester, ev.target.value);
      }

      if (ev.target.matches('[data-type-tester-control-size]')) {
        tester.querySelector('[data-type-tester-control-size]').dataset.typeTesterControlSize = '';
        changeSize(tester, ev.target.value);
      }

      if (ev.target.matches('[data-type-tester-control-tracking]')) {
        changeTracking(tester, ev.target.value);
      }

      if (ev.target.matches('[data-type-tester-control-leading]')) {
        changeLeading(tester, ev.target.value);
      }
    });
    let event = new CustomEvent('typetester.init', {
      bubbles: true
    });
    tester.dispatchEvent(event);
  };

  // Fit text
  const extend = function(obj,ext){
    for(let key in ext)
      if(ext.hasOwnProperty(key))
        obj[key] = ext[key];
    return obj;
  };

  const fitTextSize = function (el, options) {
    let settings = extend({
      'minFontSize' : -1/0,
      'maxFontSize' : 1/0
    },options);

    let fit = function (el) {
      let multiplier = 0;
      if (el.getBoundingClientRect().width) {
        multiplier = (el.parentNode.getBoundingClientRect().width / el.getBoundingClientRect().width) * 0.95;
      }
      let originalFontSize = parseFloat(window.getComputedStyle(el, null).getPropertyValue('font-size'));

      let resizer = function () {
        return Math.max(Math.min(Math.floor(originalFontSize * multiplier), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize));
      };

      // Call once to set.
      return resizer();
    };

    // return new font size
    return fit(el);
  };

  const fitText = function(tester) {
    let fontSize = fitTextSize(tester.querySelector('[data-type-tester-content] span'), {minFontSize: 10, maxFontSize: 2000});
    tester.querySelector('[data-type-tester-control-size]').setAttribute('value', fontSize.toString());
    changeSize(tester, fontSize);
  };

  const initTypeTesterGroup = function(ttgroup) {
    let maxFontSizes = [];
    let testers = ttgroup.querySelectorAll('[data-type-tester="in-group"]');

    if (testers) {
      Array.prototype.forEach.call(testers, function(tester) {
        if (tester.querySelector('[data-type-tester-control-size]') && tester.querySelector('[data-type-tester-control-size]').dataset.typeTesterControlSize === 'fit' && window.getComputedStyle(tester.parentNode).getPropertyValue('display') !== 'none') {
          maxFontSizes.push(fitTextSize(tester.querySelector('[data-type-tester-content] span'), {minFontSize: 10, maxFontSize: 2000}));
        }
      });

      let groupFontSize = Math.min(...maxFontSizes);

      Array.prototype.forEach.call(testers, function(tester) {
        if (tester.querySelector('[data-type-tester-control-size]') && tester.querySelector('[data-type-tester-control-size]').dataset.typeTesterControlSize === 'fit' && window.getComputedStyle(tester.parentNode).getPropertyValue('display') !== 'none') {
          tester.querySelector('[data-type-tester-control-size]').setAttribute('value', groupFontSize.toString());
          changeSize(tester, groupFontSize);
        }
      });
    }
  };

  window.addEventListener("load", (event) => {
    if (tt) {
      window.addEventListener('resize', function(ev) {
        Array.prototype.forEach.call(tt, function (tester) {
          if (tester.querySelector('[data-type-tester-control-size]') && tester.querySelector('[data-type-tester-control-size]').dataset.typeTesterControlSize === 'fit') {
            fitText(tester);
          } else if (!tester.querySelector('[data-type-tester-content]').style.fontSize) {
            initTypeTester(tester);
          }
        });
      });

      window.addEventListener('orientationchange', function(ev) {
        Array.prototype.forEach.call(tt, function (tester) {
          if (tester.querySelector('[data-type-tester-control-size]') && tester.querySelector('[data-type-tester-control-size]').dataset.typeTesterControlSize === 'fit') {
            fitText(tester);
          }
        });
      });

      Array.prototype.forEach.call(tt, function (tester) {
        if (tester.querySelector('[data-type-tester-control-size]') && tester.querySelector('[data-type-tester-control-size]').dataset.typeTesterControlSize === 'fit') {
          fitText(tester);
        } else if (!tester.querySelector('[data-type-tester-content]').style.fontSize) {
          initTypeTester(tester);
        }

        initTypeTesterListeners(tester);
      });
    }

    if (ttg) {
      window.addEventListener('resize', function(ev) {
        Array.prototype.forEach.call(ttg, function (ttgroup) {
          initTypeTesterGroup(ttgroup);
        });
      });

      window.addEventListener('orientationchange', function(ev) {
        Array.prototype.forEach.call(ttg, function (ttgroup) {
          initTypeTesterGroup(ttgroup);
        });
      });

      Array.prototype.forEach.call(ttg, function(ttgroup) {
        let maxFontSizes = [];
        let testers = ttgroup.querySelectorAll('[data-type-tester="in-group"]');

        if (testers) {
          Array.prototype.forEach.call(testers, function(tester) {
            if (window.getComputedStyle(tester.parentNode).getPropertyValue('display') !== 'none') {
              maxFontSizes.push(fitTextSize(tester.querySelector('[data-type-tester-content] span'), {minFontSize: 10, maxFontSize: 2000}));
            }
          });

          let groupFontSize = Math.min(...maxFontSizes);

          Array.prototype.forEach.call(testers, function(tester) {
            if (window.getComputedStyle(tester.parentNode).getPropertyValue('display') !== 'none') {
              tester.querySelector('[data-type-tester-control-size]').setAttribute('value', groupFontSize.toString());
              changeSize(tester, groupFontSize);

              initTypeTesterListeners(tester);
            }
          });
        }
      });
    }
  });

  const cleanPaste = function(ev) {
    ev.preventDefault();

    const text = (ev.clipboardData || window.clipboardData).getData('text');
    const selection = window.getSelection();

    if (selection.rangeCount) {
      selection.deleteFromDocument();
      let newTextNode = document.createTextNode(text);
      selection.getRangeAt(0).insertNode(newTextNode);
      selection.extend(newTextNode, newTextNode.length);
      selection.collapseToEnd();
    }
  };

  document.addEventListener('paste', function(ev) {
    if (ev.target.matches('[contenteditable="true"]')) {
      cleanPaste(ev);
    }
  });
})();
