import Swiper, { Pagination, Autoplay, EffectFade, Lazy } from 'swiper';

let swiperElements = document.querySelectorAll('.swiper');
let swiperCarousels = [];

Array.prototype.forEach.call(swiperElements, function(swiperElement, index) {
  let slidesPerView = swiperElement.dataset.slidesPerView;
  let speed = parseInt(swiperElement.dataset.speed, 10);
  let fade = swiperElement.dataset.fade;
  let autoplay = swiperElement.dataset.autoplay;
  let imagesToShow = parseInt(swiperElement.dataset.imagesToShow, 10);
  let lazyLoad = swiperElement.dataset.lazy;
  if (!speed) {
    speed = 3000;
  }
  let modules = [Pagination, Autoplay, EffectFade];
  if (lazyLoad) {
    modules.push(Lazy);
  }
  let options = {
    modules: modules,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  };
  if (lazyLoad) {
    // Disable preloading of all images
    options['preloadImages'] = false;
    // Enable lazy loading
    options['lazy'] = true;
  }
  if (slidesPerView) {
    options['slidesPerView'] = slidesPerView;
  }
  if (imagesToShow === 2) {
    options['breakpoints'] = {
      640: {
        slidesPerView: 2,
        spaceBetween: 16
      },
    };
  }
  if (imagesToShow === 3) {
    options['breakpoints'] = {
      640: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      1536: {
        slidesPerView: 3,
        spaceBetween: 16
      },
    };
  }
  if (autoplay && !imagesToShow) {
    options['autoplay'] = {
      delay: speed,
      disableOnInteraction: false,
    };
  }
  if (fade) {
    options['effect'] = 'fade';
    options['fadeEffect'] = {
      crossFade: true,
    };
  }
  swiperCarousels[index] = new Swiper(swiperElement, options);
});
